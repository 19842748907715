<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="20" :offset="2">
        <el-card class="box-card">
          <p class="text-2xl pb-12">Thank You For Your Request!</p>
          <div class="flex justify-center">
            <lottie-player
              autoplay
              loop
              mode="normal"
              src="https://assets9.lottiefiles.com/packages/lf20_atippmse.json"
              style="width: 15%"
            >
            </lottie-player>
          </div>
          <div class="content pt-3">
            <p class="text-xl">Your Request Number:<b> TBT-{{formId}}</b></p>
            <p class="text-sm pt-3">
              We got your request! Our Team will contact with you soon. <br />
              We did sent a mail brief of the request to your mail address.
              <br />
              Please check your mailbox. <br />
              <b>If you think somethings went wrong, please contact with us.</b>
            </p>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import "@lottiefiles/lottie-player";

export default {
  data() {
    return {
      formId:null,
    }
  },
  async created() {
    const formId = this.$route.params.id;
    this.formId = formId;
  },
};
</script>
